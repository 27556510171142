import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Amplify, { Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
import { CsvToHtmlTable } from 'react-csv-to-table';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { TwitterTimelineEmbed  } from 'react-twitter-embed';


document.title = 'My Game 3G'

var thisyear = new Date().getFullYear();

const options = [];
for (var o=thisyear; o > 2012; o--) {
  options.push(o);
}



Amplify.configure(awsconfig);



function App() {

  const [standings, setStandings] = useState([]);
  const [year, setYear] = useState(thisyear);
  const [message, setMessage] = useState();

  useEffect(() => {
    console.log('trying to get standings...');
    getStandings();
  });

  const getyear = () => {
    return JSON.stringify(year);
  }


  const iditarodstandings = () => {
    window.open('https://iditarod.com/race/' + getyear() + '/standings/');
  }

  const racemap = () => {
    window.open("https://iditarod.com/race-map");
  }

  const getmessage =  () => {
    return message;
  }

  const standscopy = () => {
    return JSON.parse(JSON.stringify(standings));
  }
  
  const getStandings = async() => {
    var stands = [];
    var l = await Storage.list('standings/' + getyear());
    if (l.length > 0) {
      var diffMs = (new Date() - l[0].lastModified);
      var diffMins = Math.round(diffMs /1000/60);
      setMessage('Last updated ' + diffMins + ' min ago');
    }
    console.log(l);
    for (var i=0; i<l.length; i++) {
        try {
          
          var stand = await Storage.get('standings/'+ getyear() + '/' + i + '.csv', { download: true })
            .then(result => {
                return result.Body.text();
            })
            .catch(err => {
                console.log('error getting standings');
                console.log(err)
                return '';
            });
          if (stand !== '') {
            
            stands.push(stand);
          }
          
        } catch {

        }
    }
    
    setStandings(stands);
  }



  return (
    <div>
    <style>
      {`
        table {
          border-collapse: collapse;
          width: 80%;
          margin: 20px;
        }
        th, td {
          padding: 0.25rem;
          text-align: left;
          border: 1px solid #ccc;
        }
      `}
    </style>
    <div className="App">
      <br/>
      <h1>MY GAME 3G</h1>
      <br/><br/>
      <div>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="The_Iditarod"
        options={{height: 400}}
      />
      </div>
      <br/>
      <br/>
      <a href="#" onClick={() => racemap()} style={{float: 'right'}}>Race Map</a>
      <a href="#" onClick={() => iditarodstandings()} style={{float: 'left'}}>Iditarod Standings Page</a>
      <br/>
      <br/>
      <div style={{width: '50', margin: '20'}}>
        <Dropdown 
        options={options} 
        onChange={(v) => {setYear(v.value)}} 
        value={getyear()} placeholder="Select a year" />
       </div>
       <div>
         <br/><br/>
         <div style={{float: 'left', color: 'green'}}><i>{getmessage()}</i></div>
         <br/>
       </div>
      {standscopy().map((standing, index) => (
                <div><br/><h2 style={{float: "left"}}>{index===0 ? "Standings/Finished" : "Racing/Scratched"}</h2>
                <br/>
                <br/>
                <br/>
                  <CsvToHtmlTable data={standing} csvDelimiter="," />
                </div>
            ))}
      
    </div>
    </div>
  );
}

export default App;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e176667d-fedc-4b7b-81e9-e70ecef2560d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_NyjOB4nkh",
    "aws_user_pools_web_client_id": "55apfca3gl9ik8tr2stbj6qkle",
    "oauth": {},
    "aws_user_files_s3_bucket": "mygame3g",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
